import request from '@/utils/request'
// 设备绑定同步管理
// 获取部门下拉树及设备
export function deviceTree(data) {
  return request({
    url: '/jt808/device/tree',
    method: 'post',
    data: data
  })
}

// 获取部门下拉树及围栏
export function treeArea(data) {
  return request({
    url: '/jt808/device/treeArea',
    method: 'post',
    data: data
  })
}
