<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="路段" prop="name">
                <a-tree-select
                  v-model="zoneIds"
                  :replaceFields="replaceFields"
                  style="width: 100%"
                  :tree-data="treeData"
                  :show-checked-strategy="SHOW_PARENT"
                  tree-checkable
                  @change="treeChange"
                  tree-node-filter-prop="title"
                  placeholder="请选择路段"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="结果状态" prop="status">
                <a-select default-value="" v-model="queryParam.status">
                  <a-select-option value=""> 全部 </a-select-option>
                  <a-select-option value="0"> 异常 </a-select-option>
                  <a-select-option value="1"> 正常 </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="日期" prop="createTime">
                <a-range-picker value-format="YYYY-MM-DD" format="YYYY-MM-DD" @change="handleCreateTime" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item prop="createTime">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <area-create-form ref="areaCreateForm" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <div slot="name" slot-scope="text, record">
          <a class="route-name" href="#">
            <span @click="toFence(record)">{{ record.name }}</span></a
          >
        </div>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record)" v-hasPermi="['tenant:jt808:deviceSim:query']">
            <a-icon type="info-circle" />查看
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listSamplingRecord } from '@/api/jt808/samplingRecord'
import CreateForm from './modules/CreateForm'
import AreaCreateForm from '@/views/jt808/areaManager/modules/CreateForm'
import { treeArea } from '@/api/iot/device'
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
export default {
  name: 'AreaManager',
  components: {
    CreateForm,
    AreaCreateForm,
    ATreeSelect: TreeSelect
  },
  data() {
    return {
      zoneIds: [],
      SHOW_PARENT,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        areaIds: [],
        name: null,
        deptId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '路段名称',
          scopedSlots: { customRender: 'name' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '班次时段',
          dataIndex: 'startTime',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return record.startTime + '-' + record.endTime
          }
        },

        {
          title: '应在岗人数',
          dataIndex: 'mustOnDutyCount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '实际在岗人数',
          dataIndex: 'realOnDutyCount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '抽查结果',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            if (record.status === 0) return '异常'
            else {
              return '正常'
            }
          }
        },
        {
          title: '抽查时间',
          dataIndex: 'createTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      treeData: [],
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' }
    }
  },
  filters: {},
  created() {
    this.getList()
    this.initCleanerTree()
  },
  computed: {},
  watch: {},
  methods: {
    treeChange(e) {
      console.log('treeChange', e)
      this.treeChangeValue = e
      let areaIds = []
      for (const item of this.treeChangeValue) {
        this.curFlag = false
        this.curZoneArr = []
        console.log('treeChangeValue——item', item)
        this.searchAreaId(item)
        areaIds = areaIds.concat(this.curZoneArr)
      }
      this.queryParam.areaIds = []
      for (const item of areaIds) {
        this.queryParam.areaIds.push(item.id.substr(4))
      }
      console.log('areaIds', areaIds)
    },
    searchAreaId(id, children) {
      const data = children || this.treeData
      for (const item of data) {
        if (!id || item.id === id) {
          this.curFlag = true
          if (item.children && item.children.length > 0) {
            this.searchAreaId(null, item.children)
          } else {
            this.curZoneArr.push(item)
          }
        } else {
          if (!this.curFlag && item.children) {
            this.searchAreaId(id, item.children)
          }
        }
      }
    },
    initCleanerTree() {
      console.log('initCleanerTree')
      const formData = new FormData()
      formData.append('type', 1)
      treeArea(formData).then((response) => {
        this.treeData = response.data
      })
    },
    toFence(record) {
      this.$refs.areaCreateForm.handleUpdate(null, record.areaId, 'read')
    },
    /** 查询sim卡管理列表 */
    getList() {
      this.loading = true
      listSamplingRecord(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        name: undefined,
        deptId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    handleCreateTime(date, dateString) {
      if (dateString && dateString.length === 2) {
        this.queryParam.params = {}
        if (dateString[0] && dateString[1]) {
          this.queryParam.params['beginCreateTime'] = dateString[0]
          this.queryParam.params['endCreateTime'] = dateString[1]
        }
      }
    }
  }
}
</script>
