var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "div",
            { staticClass: "table-page-search-wrapper" },
            [
              _c(
                "a-form",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 48 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 6, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "路段", prop: "name" } },
                            [
                              _c("a-tree-select", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  replaceFields: _vm.replaceFields,
                                  "tree-data": _vm.treeData,
                                  "show-checked-strategy": _vm.SHOW_PARENT,
                                  "tree-checkable": "",
                                  "tree-node-filter-prop": "title",
                                  placeholder: "请选择路段",
                                },
                                on: { change: _vm.treeChange },
                                model: {
                                  value: _vm.zoneIds,
                                  callback: function ($$v) {
                                    _vm.zoneIds = $$v
                                  },
                                  expression: "zoneIds",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 6, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "结果状态", prop: "status" } },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: { "default-value": "" },
                                  model: {
                                    value: _vm.queryParam.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParam, "status", $$v)
                                    },
                                    expression: "queryParam.status",
                                  },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "" } },
                                    [_vm._v(" 全部 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "0" } },
                                    [_vm._v(" 异常 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "1" } },
                                    [_vm._v(" 正常 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 6, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "日期", prop: "createTime" } },
                            [
                              _c("a-range-picker", {
                                attrs: {
                                  "value-format": "YYYY-MM-DD",
                                  format: "YYYY-MM-DD",
                                },
                                on: { change: _vm.handleCreateTime },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 6, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { prop: "createTime" } },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "search" } }),
                                  _vm._v("查询"),
                                ],
                                1
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.resetQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "redo" } }),
                                  _vm._v("重置"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-toolbar",
            {
              attrs: { loading: _vm.loading, refresh: "", tid: "1" },
              on: { refreshQuery: _vm.getList },
            },
            [_c("div", { staticClass: "table-operations" })]
          ),
          _c("create-form", { ref: "createForm", on: { ok: _vm.getList } }),
          _c("area-create-form", { ref: "areaCreateForm" }),
          _c("a-table", {
            attrs: {
              loading: _vm.loading,
              size: _vm.tableSize,
              rowKey: "id",
              tid: "1",
              columns: _vm.columns,
              "data-source": _vm.list,
              "row-selection": {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
              },
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function (text, record) {
                  return _c("div", {}, [
                    _c(
                      "a",
                      { staticClass: "route-name", attrs: { href: "#" } },
                      [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.toFence(record)
                              },
                            },
                          },
                          [_vm._v(_vm._s(record.name))]
                        ),
                      ]
                    ),
                  ])
                },
              },
              {
                key: "operation",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["tenant:jt808:deviceSim:query"],
                            expression: "['tenant:jt808:deviceSim:query']",
                          },
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.$refs.createForm.handleUpdate(record)
                          },
                        },
                      },
                      [
                        _c("a-icon", { attrs: { type: "info-circle" } }),
                        _vm._v("查看 "),
                      ],
                      1
                    ),
                  ])
                },
              },
            ]),
          }),
          _c("a-pagination", {
            staticClass: "ant-table-pagination",
            attrs: {
              "show-size-changer": "",
              "show-quick-jumper": "",
              current: _vm.queryParam.pageNum,
              total: _vm.total,
              "page-size": _vm.queryParam.pageSize,
              showTotal: function (total) {
                return "共 " + total + " 条"
              },
            },
            on: {
              showSizeChange: _vm.onShowSizeChange,
              change: _vm.changeSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }